'use client';
import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { Card } from '@/components/shared/material';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { PortableText } from '@portabletext/react';
import { components } from '@components/sections/PortableTextComponents';
import { PortableTextBlock } from 'sanity';
import Link from 'next/link';
import { getHomePageUrl } from '@/lib/pathUtils';
import YouTube, { YouTubeProps, YouTubeEvent } from 'react-youtube';
import { m, useInView } from 'framer-motion';
import { useMatchMedia } from '@/lib/matchMedia';
import { HandsClapping } from '@phosphor-icons/react';

type Slide = {
  type: 'image' | 'quote' | 'quote-light' | 'video';
  articleImage?: boolean;
  image?: {
    url: string;
    alt: string;
    blurDataUrl: string;
  };
  video?: {
    video: {
      id: string;
    };
    previewTiming: {
      start: number;
      end: number;
    };
  };
  text?: PortableTextBlock;
  quote?: PortableTextBlock;
  quoteSource?: PortableTextBlock;
  quoteImage?: {
    url: string;
    alt: string;
    blurDataUrl: string;
  };
  disablePersonProfileImageShadow?: boolean;
  article?: {
    slug: string;
    image: {
      url: string;
      alt: string;
      blurDataUrl: string;
    };
  };
};

function TestimonialCard(props: { slide: Slide; isLink: boolean }) {
  const slide = props.slide;
  const isLink = props.isLink;
  return (
    <>
      {slide.type === 'image' && (
        <>
          {slide.articleImage &&
            slide.article &&
            slide.article.image &&
            slide.article.image.url && (
              <Image
                placeholder='blur'
                blurDataURL={slide.article.image.blurDataUrl}
                className='absolute left-0 top-0 z-[0] h-full w-full object-cover'
                width={500}
                height={500}
                src={slide.article.image.url}
                alt={slide.article.image.alt ?? 'iKhokha'}
              />
            )}

          {!slide.articleImage && slide.image && slide.image.url && (
            <Image
              placeholder='blur'
              blurDataURL={slide.image.blurDataUrl}
              className='absolute left-0 top-0 z-[0] h-full w-full object-cover'
              width={500}
              height={500}
              src={slide.image.url}
              alt={slide.image.alt ?? 'iKhokha'}
            />
          )}

          {slide.text && (
            <div className='flex h-full w-full flex-col'>
              <div className='relative z-10 mt-auto w-full rounded-xl border border-white/50 bg-gradient-to-b from-white/[15%] to-gray-500/[15%] px-3 py-2 text-2xl font-medium backdrop-blur-[2px] lg:px-5 lg:py-3.5'>
                <div className='text-xl text-white shadow-black/10 [text-shadow:_0_2px_2px_var(--tw-shadow-color)] lg:text-2xl [&_path]:fill-white'>
                  <PortableText
                    value={slide.text}
                    components={components}
                  ></PortableText>
                  <svg
                    className='ml-3 inline-block transition duration-500 group-hover:translate-x-2'
                    xmlns='http://www.w3.org/2000/svg'
                    width='17'
                    height='16'
                    viewBox='0 0 17 16'
                    fill='none'
                  >
                    <path
                      d='M9.81223 15.4466L16.2143 9.0506C16.3186 8.95578 16.4019 8.84051 16.4589 8.7121C16.5159 8.58369 16.5453 8.44494 16.5453 8.30466C16.5453 8.16438 16.5159 8.02564 16.4589 7.89723C16.4019 7.76881 16.3186 7.65354 16.2143 7.55872L9.81223 1.16268C9.62179 0.977924 9.37156 0.865835 9.10577 0.84623C8.83997 0.826625 8.57574 0.900765 8.35979 1.05554C8.23002 1.14171 8.12192 1.25621 8.04381 1.39025C7.96569 1.52429 7.91964 1.67429 7.9092 1.82873C7.89876 1.98316 7.9242 2.13791 7.98357 2.28108C8.04294 2.42424 8.13466 2.55201 8.25167 2.65456L12.8785 7.23732L1.47524 7.23732C1.19718 7.248 0.934093 7.36495 0.741174 7.56365C0.548254 7.76235 0.440491 8.02735 0.440491 8.30306C0.440491 8.57878 0.548254 8.84378 0.741174 9.04248C0.934093 9.24118 1.19718 9.35813 1.47524 9.3688L12.8801 9.3688L8.25328 13.9532C8.13627 14.0557 8.04455 14.1835 7.98518 14.3266C7.92581 14.4698 7.90037 14.6246 7.91081 14.779C7.92126 14.9334 7.96731 15.0834 8.04542 15.2175C8.12354 15.3515 8.23164 15.466 8.36141 15.5522C8.57736 15.707 8.84159 15.7811 9.10738 15.7615C9.37317 15.7419 9.62341 15.6298 9.81385 15.445L9.81223 15.4466Z'
                      fill='none'
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {slide.type.startsWith('quote') && (
        <>
          {slide.quote && (
            <div className='flex h-full w-full flex-col lg:pl-5'>
              <div className='relative z-10 w-full rounded-xl lg:py-3.5 lg:pr-5'>
                <div className={`relative p-3 lg:p-6`}>
                  <span
                    className={`heading-xl absolute left-0 top-0 -translate-x-2 transform font-display font-bold leading-none lg:-translate-y-2 ${
                      slide.type === 'quote-light'
                        ? 'text-yellow'
                        : 'text-charcoal'
                    }`}
                  >
                    “
                  </span>
                  <span className='text-lg text-charcoal [&_strong]:text-orange'>
                    <PortableText
                      value={slide.quote}
                      components={components}
                    ></PortableText>
                  </span>
                  <span
                    className={`heading-xl inline-block translate-x-3 translate-y-5 transform font-display font-bold leading-[0px] lg:translate-y-10 ${
                      slide.type === 'quote-light'
                        ? 'text-yellow'
                        : 'text-charcoal'
                    }`}
                  >
                    ”
                  </span>
                </div>
                {slide.quoteSource && (
                  <div className='max-w-[90%] px-4 sm:max-w-[unset]'>
                    <span className='text-base font-bold italic text-charcoal'>
                      {'- '}
                      <PortableText
                        value={slide.quoteSource}
                        components={components}
                      ></PortableText>
                    </span>
                  </div>
                )}
              </div>

              <div
                className={`mt-auto flex items-end gap-10 pl-6 text-charcoal lg:pb-5 [&_path]:fill-charcoal ${
                  isLink ? 'justify-between' : 'justify-end'
                }`}
              >
                {isLink && (
                  <div className='pb-2 text-lg font-bold lg:text-xl'>
                    <span>Read more</span>
                    <svg
                      className='ml-3 inline-block transition duration-500 group-hover:translate-x-2'
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='16'
                      viewBox='0 0 17 16'
                      fill='none'
                    >
                      <path
                        d='M9.81223 15.4466L16.2143 9.0506C16.3186 8.95578 16.4019 8.84051 16.4589 8.7121C16.5159 8.58369 16.5453 8.44494 16.5453 8.30466C16.5453 8.16438 16.5159 8.02564 16.4589 7.89723C16.4019 7.76881 16.3186 7.65354 16.2143 7.55872L9.81223 1.16268C9.62179 0.977924 9.37156 0.865835 9.10577 0.84623C8.83997 0.826625 8.57574 0.900765 8.35979 1.05554C8.23002 1.14171 8.12192 1.25621 8.04381 1.39025C7.96569 1.52429 7.91964 1.67429 7.9092 1.82873C7.89876 1.98316 7.9242 2.13791 7.98357 2.28108C8.04294 2.42424 8.13466 2.55201 8.25167 2.65456L12.8785 7.23732L1.47524 7.23732C1.19718 7.248 0.934093 7.36495 0.741174 7.56365C0.548254 7.76235 0.440491 8.02735 0.440491 8.30306C0.440491 8.57878 0.548254 8.84378 0.741174 9.04248C0.934093 9.24118 1.19718 9.35813 1.47524 9.3688L12.8801 9.3688L8.25328 13.9532C8.13627 14.0557 8.04455 14.1835 7.98518 14.3266C7.92581 14.4698 7.90037 14.6246 7.91081 14.779C7.92126 14.9334 7.96731 15.0834 8.04542 15.2175C8.12354 15.3515 8.23164 15.466 8.36141 15.5522C8.57736 15.707 8.84159 15.7811 9.10738 15.7615C9.37317 15.7419 9.62341 15.6298 9.81385 15.445L9.81223 15.4466Z'
                        fill='none'
                      />
                    </svg>
                  </div>
                )}

                {slide.type === 'quote' ? (
                  <>
                    {slide.quoteImage &&
                      slide.quoteImage.url &&
                      slide.quoteImage.url !== '' && (
                        <div
                          className={`h-16 w-16 overflow-hidden rounded-full ${!slide?.disablePersonProfileImageShadow ? 'border border-charcoal bg-black/10' : ''} lg:h-24 lg:w-24`}
                        >
                          <Image
                            className='h-full w-full object-cover'
                            width={80}
                            height={80}
                            src={slide.quoteImage.url}
                            alt={
                              slide.quoteImage.alt ??
                              'iKhokha testimonial image'
                            }
                            placeholder='blur'
                            blurDataURL={slide.quoteImage.blurDataUrl}
                          />
                        </div>
                      )}
                  </>
                ) : (
                  <>
                    <div className='tranlate-x-2 absolute flex h-10 w-10 flex-shrink-0 transform items-center justify-center overflow-hidden rounded-full border bg-yellow shadow-lg shadow-black/[5%] sm:relative lg:h-14 lg:w-14 lg:translate-y-4'>
                      {slide.quoteImage &&
                      slide.quoteImage.url &&
                      slide.quoteImage.url !== '' ? (
                        <Image
                          className='h-6 w-6'
                          width={40}
                          height={40}
                          src={slide.quoteImage.url}
                          alt={slide.quoteImage.alt ?? 'iKhokha'}
                          placeholder='blur'
                          blurDataURL={slide.quoteImage.blurDataUrl}
                        />
                      ) : (
                        <HandsClapping weight='fill' className='text-3xl' />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}

      {!slide.type.startsWith('quote') && (
        <>
          {/* Backgound overlay */}
          <div className='absolute left-0 top-0 z-[2] h-full w-full rounded-3xl bg-gradient-to-b from-charcoal/[0.5%] to-charcoal/30' />
        </>
      )}
    </>
  );
}

export default function TestimonialSlide({
  slide,
  showVideo,
  activeSlide,
  index,
}: {
  slide: Slide;
  showVideo?: (videoId: string) => void;
  activeSlide: number;
  index: number;
}) {
  const slideRef = useRef(null);
  const isInViewIntial = useInView(slideRef, { amount: 0.4, once: true });
  const isInView = useInView(slideRef, { amount: 0.5 });
  const cardClass = `w-full group hover:-translate-y-1 transition duration-300 transform flex flex-col !shadow-xl hover:![box-shadow:0_25px_25px_-12px_var(--tw-shadow-color)] shadow-black/20 group-hover:translate-y-[-5px] transform duration-300 aspect-square border-0 rounded-3xl overflow-hidden
        ${slide.type === 'quote' ? 'bg-gradient-to-b bg-gradient-yellow' : ''}
        ${slide.type === 'quote-light' ? 'bg-white border border-yellow' : ''}
        ${slide.type === 'video' ? 'bg-gradient-to-b bg-gradient-charcoal' : ''}
    `;

  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const [player, setPlayer] = useState<YouTubeEvent['target'] | null>(null);

  const videoIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const videoStarted = useRef<boolean>(false);

  const isDesktopResolution = useMatchMedia('(min-width:1080px)', true);

  const playerStart =
    slide.type === 'video' && slide.video ? slide.video.previewTiming.start : 0;
  const playerEnd =
    slide.type === 'video' && slide.video ? slide.video.previewTiming.end : 0;

  const showVideoModal = () => {
    let videoId =
      slide.type === 'video' && slide.video?.video?.id
        ? slide.video.video.id
        : '';
    if (showVideo) showVideo(videoId);
  };

  useEffect(() => {
    if (slide.type === 'video') {
      if (
        (isMouseOver && isDesktopResolution && isInView) ||
        (activeSlide === index && !isDesktopResolution && isInView)
      ) {
        if (!videoStarted.current) {
          videoStarted.current = true;
        }
        setIsVideoPlaying(true);
        // Check if player state is set and has properties
        if (player && player.g) {
          player.seekTo(playerStart);
          player.playVideo();
        }
        videoIntervalRef.current = setInterval(
          () => {
            if (player && player.g && playerStart) player.seekTo(playerStart);
          },
          (playerEnd - playerStart) * 1000
        );
      } else {
        if (videoIntervalRef.current) {
          clearInterval(videoIntervalRef.current);
          videoIntervalRef.current = null;
        }
        setIsVideoPlaying(false);
        // Check if player state is set and has properties
        if (player && player.g && videoStarted.current) {
          player.seekTo(playerStart);
          player.pauseVideo();
        }

        if (player && player.g && !videoStarted.current) {
          player.playVideo();
          setTimeout(() => {
            player.seekTo(playerStart);
            player.pauseVideo();
          }, 500);
        }
      }
    }
  }, [
    isMouseOver,
    player,
    playerEnd,
    playerStart,
    slide,
    isInView,
    activeSlide,
    index,
    isDesktopResolution,
  ]);

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    setPlayer(event.target);
    event.target.seekTo(playerStart);
    event.target.playVideo();
  };

  const opts: YouTubeProps['opts'] = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      mute: 1,
      playinline: 1,
      disablekb: 1,
      start: playerStart,
      end: playerEnd,
      loop: 1,
      controls: 0,
    },
    iframeClassName: 'w-full h-full',
  };

  return (
    <>
      {slide.type === 'video' ? (
        <>
          <div className='block aspect-[4/5] h-full w-full sm:aspect-[1/1]'>
            <Card
              ref={slideRef}
              aria-label='Play youtube video'
              className={cardClass + ' h-full p-5'}
              onMouseOver={() => setIsMouseOver(true)}
              onMouseLeave={() => setIsMouseOver(false)}
              onClick={() => showVideoModal()}
            >
              {slide.image && slide.image.url && (
                <m.div
                  animate={
                    isVideoPlaying ||
                    (activeSlide === index && !isDesktopResolution && isInView)
                      ? { opacity: 0 }
                      : { opacity: 1 }
                  }
                  className='absolute left-0 top-0 z-10 h-full w-full overflow-hidden rounded-3xl'
                >
                  <Image
                    placeholder='blur'
                    blurDataURL={slide.image.blurDataUrl}
                    className='h-full w-full object-cover'
                    width={500}
                    height={500}
                    src={slide.image.url}
                    alt={slide.image.alt ?? 'iKhokha testimonial slide image'}
                  />
                </m.div>
              )}
              {slide.video && isInViewIntial && slide?.video?.video?.id && (
                <div className='full w-full min-w-[420px]'>
                  {/* @ts-ignore  */}
                  <YouTube
                    videoId={slide.video.video.id}
                    opts={opts}
                    className='absolute left-0 top-0 h-full w-full overflow-hidden rounded-3xl object-cover'
                    iframeClassName='absolute left-[-60%] top-[-60%] h-[220%] w-[240%]  sm:left-[-45%] sm:top-[-45%] sm:h-[190%] sm:w-[190%] rounded-3xl object-cover pointer-events-none'
                    onReady={onPlayerReady}
                  />
                </div>
              )}

              {slide.text && (
                <div className='flex h-full w-full flex-col'>
                  <div className='relative z-10 mt-auto w-full rounded-xl border border-white/50 bg-gradient-to-b from-white/[15%] to-gray-500/[15%] font-medium backdrop-blur-[2px]'>
                    <div className='px-3 py-2 text-xl text-white shadow-black/10 [text-shadow:_0_2px_2px_var(--tw-shadow-color)] lg:px-5 lg:py-3.5 lg:text-2xl [&_path]:fill-white'>
                      <span className='inline-block max-w-[355px]'>
                        <PortableText
                          value={slide.text}
                          components={components}
                        ></PortableText>

                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='ml-3 inline-block transition duration-500 group-hover:scale-110'
                          width='22'
                          height='22'
                          viewBox='0 0 22 22'
                          fill='none'
                        >
                          <path
                            d='M10.5625 0C8.47344 0 6.43129 0.61948 4.69429 1.7801C2.9573 2.94072 1.60348 4.59036 0.804027 6.52041C0.00457657 8.45045 -0.204596 10.5742 0.20296 12.6231C0.610517 14.6721 1.6165 16.5541 3.09369 18.0313C4.57088 19.5085 6.45294 20.5145 8.50186 20.922C10.5508 21.3296 12.6746 21.1204 14.6046 20.321C16.5346 19.5215 18.1843 18.1677 19.3449 16.4307C20.5055 14.6937 21.125 12.6516 21.125 10.5625C21.122 7.76206 20.0083 5.07716 18.0281 3.09695C16.0478 1.11674 13.3629 0.00295732 10.5625 0ZM10.5625 19.5C8.79483 19.5 7.06686 18.9758 5.5971 17.9938C4.12733 17.0117 2.98179 15.6158 2.30533 13.9827C1.62887 12.3496 1.45188 10.5526 1.79674 8.81888C2.14159 7.08517 2.99281 5.49266 4.24274 4.24273C5.49267 2.9928 7.08518 2.14159 8.81888 1.79673C10.5526 1.45188 12.3496 1.62887 13.9827 2.30533C15.6159 2.98178 17.0117 4.12733 17.9938 5.59709C18.9758 7.06685 19.5 8.79483 19.5 10.5625C19.4973 12.932 18.5548 15.2038 16.8793 16.8793C15.2038 18.5548 12.9321 19.4973 10.5625 19.5ZM14.2634 9.88609L9.38844 6.63609C9.26605 6.55443 9.12378 6.50754 8.97682 6.50042C8.82986 6.4933 8.68373 6.52622 8.55402 6.59567C8.4243 6.66511 8.31588 6.76847 8.24032 6.89472C8.16477 7.02097 8.12491 7.16537 8.125 7.3125V13.8125C8.12491 13.9596 8.16477 14.104 8.24032 14.2303C8.31588 14.3565 8.4243 14.4599 8.55402 14.5293C8.68373 14.5988 8.82986 14.6317 8.97682 14.6246C9.12378 14.6175 9.26605 14.5706 9.38844 14.4889L14.2634 11.2389C14.3749 11.1647 14.4663 11.0642 14.5295 10.9462C14.5927 10.8282 14.6258 10.6964 14.6258 10.5625C14.6258 10.4286 14.5927 10.2968 14.5295 10.1788C14.4663 10.0608 14.3749 9.96026 14.2634 9.88609ZM9.75 12.2941V8.83594L12.348 10.5625L9.75 12.2941Z'
                            fill='none'
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </div>
        </>
      ) : (
        <>
          {slide.article && slide.article.slug ? (
            <>
              <Link
                ref={slideRef}
                className='aspct-square block h-full w-full [aspect-ratio:_5_/6] xs:[aspect-ratio:_1_/_1]'
                href={`${getHomePageUrl()}/blog/${slide.article.slug}`}
              >
                <Card
                  className={cardClass + ' h-full p-5'}
                  onMouseOver={() => setIsMouseOver(true)}
                  onMouseLeave={() => setIsMouseOver(false)}
                >
                  <TestimonialCard isLink={true} slide={slide} />
                </Card>
              </Link>
            </>
          ) : (
            <>
              <div
                ref={slideRef}
                className='aspct-square block h-full w-full [aspect-ratio:_1_/_1]'
              >
                <Card
                  className={cardClass + ` h-full p-5`}
                  onMouseOver={() => setIsMouseOver(true)}
                  onMouseLeave={() => setIsMouseOver(false)}
                >
                  <TestimonialCard isLink={false} slide={slide} />
                </Card>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
